import {createEffect, createSignal} from "solid-js";
import GiftIllustration from "../../assets/images/gift-illustration.png";
import {waitlistNumber} from "../../pages/Home";
import {setJoinWaitlistModalOpen} from "./JoinWaitlistModal";
import AOS from "aos";


export const [scrollPopupOpen, setScrollPopupOpen] = createSignal(true);

export default function ScrollPopup() {

    const isMobile = window.matchMedia("(max-width: 768px)");
    return (
        <div class={`fixed left-[5px] top-[5px] right-[5px] md:left-auto md:top-auto md:bottom-[16px] md:right-[16px] z-[999999] ${!scrollPopupOpen() ? 'hidden' : ''}`} data-aos={isMobile ? "fade-down" : "fade-left"} data-aos-once="false" data-aos-anchor="#features-tab-0">
            {/* Modal content */}
            <div onClick={(e) => {
                console.log(e, e.target.tagName, e.target.tagName !== 'path' && e.target.tagName !== 'svg');
                if(e.target.tagName !== 'path' && e.target.tagName !== 'svg' ) setJoinWaitlistModalOpen(true)
            }} class="relative cursor-pointer font-inter antialiased bg-slate-950 text-slate-200 tracking-tight border border-transparent rounded-2xl shadow-2xl [background:linear-gradient(theme(colors.slate.950),_theme(colors.slate.950))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-2xl
                        hover:[background:linear-gradient(theme(colors.slate.950),_theme(colors.slate.950))_padding-box,_conic-gradient(theme(colors.gold.400),_theme(colors.gold.700)_25%,_theme(colors.gold.700)_75%,_theme(colors.gold.400)_100%)_border-box]">

                <button id="scroll-popup-close-btn" onClick={(e) => setScrollPopupOpen(false)} type="button" class="z-10 absolute top-1 right-1 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>

                <section className="relative">


                    <div className="relative">
                        <div className="w-max md:w-auto md:max-w-[360px] flex pt-8 pl-12 pr-10 pb-4">
                            {/*<svg xmlns="http://www.w3.org/2000/svg" class="h-[80px] w-[80px]" style={{zoom: 10}}>*/}
                            {/*    <defs>*/}
                            {/*        <linearGradient id="bs2-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">*/}
                            {/*            <stop offset="0%" stop-color="#ffbf00"/>*/}
                            {/*            <stop offset="100%" stop-color="#ffbf00" stop-opacity="0"/>*/}
                            {/*        </linearGradient>*/}
                            {/*    </defs>*/}
                            {/*    <path fill="currentColor" d="M12 2a2.5 2.5 0 0 1 2 4.001L16 6a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 4 15.5V11a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1l2 .001a2.5 2.5 0 1 1 4-3A2.49 2.49 0 0 1 12 2Zm-2.5 9H5v4.5A1.5 1.5 0 0 0 6.5 17h3v-6Zm5.5 0h-4.5v6h3a1.5 1.5 0 0 0 1.5-1.5V11ZM9.5 7H4v3h5.5V7ZM16 7h-5.5v3H16V7Zm-4-4a1.5 1.5 0 0 0-1.5 1.5V6H12a1.5 1.5 0 0 0 0-3ZM8 3a1.5 1.5 0 0 0-.144 2.993L8 6h1.5V4.5l-.007-.144A1.5 1.5 0 0 0 8 3Z"/>*/}
                            {/*</svg>*/}

                             {/*Illustration */}
                            <div className="absolute -top-[20px] -left-[50px] pointer-events-none" aria-hidden="true">
                                <img src={GiftIllustration} className="max-w-none -rotate-45" width="150" height="100" alt="Page Illustration"/>
                            </div>

                            <div className="w-full">
                                <h5 className="text-[18px] bg-clip-text text-transparent bg-gradient-to-r from-gold-500 to-gold-200">
                                    <span>Gain access to exclusive deals!</span>
                                </h5>
                                <div className="flex justify-between items-center">
                                    <a className="inline-flex bg-clip-text text-sm text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                                        (<b>{waitlistNumber()}</b>&nbsp;people have already signed up)
                                    </a>
                                </div>
                            </div>
                            {/* Logo */}
                            {/*<div className="mb-5">*/}
                            {/*    <div className="inline-flex">*/}
                            {/*        <div className="relative flex items-center justify-center w-28 h-28">*/}
                            {/*            <img className="relative" src={Logo} alt="Olion"/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* Page title */}

                            {/*<div*/}
                            {/*    className="absolute left-0 top-0 opacity-50 pointer-events-none"*/}
                            {/*    aria-hidden="true">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">*/}
                            {/*        <defs>*/}
                            {/*            <linearGradient id="bs2-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">*/}
                            {/*                <stop offset="0%" stop-color="#ffbf00"/>*/}
                            {/*                <stop offset="100%" stop-color="#ffbf00" stop-opacity="0"/>*/}
                            {/*            </linearGradient>*/}
                            {/*        </defs>*/}
                            {/*        <path width="100%" height="100%" fill="currentColor" d="M12 2a2.5 2.5 0 0 1 2 4.001L16 6a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 4 15.5V11a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1l2 .001a2.5 2.5 0 1 1 4-3A2.49 2.49 0 0 1 12 2Zm-2.5 9H5v4.5A1.5 1.5 0 0 0 6.5 17h3v-6Zm5.5 0h-4.5v6h3a1.5 1.5 0 0 0 1.5-1.5V11ZM9.5 7H4v3h5.5V7ZM16 7h-5.5v3H16V7Zm-4-4a1.5 1.5 0 0 0-1.5 1.5V6H12a1.5 1.5 0 0 0 0-3ZM8 3a1.5 1.5 0 0 0-.144 2.993L8 6h1.5V4.5l-.007-.144A1.5 1.5 0 0 0 8 3Z"/>*/}
                            {/*    </svg>*/}
                            {/*</div>*/}


                            {/*<h1 className="h4 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">Join the waitlist today</h1>*/}

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
